<template>
  <v-layout row wrap>
    <v-flex xs12>
      <app-title title="Configuración Tienda" :subtitle="`Solo para la ${store.name}`"> </app-title>
    </v-flex>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <app-title-card title="Datos básicos" subtitle="Nombre, email, dirección, etc." />
        <v-divider></v-divider>
        <v-card-text>
          <v-container grid-list-lg fluid pa-0>
            <v-layout row wrap>
              <v-flex xs6>
                <v-text-field v-model="detail.name" label="Nombre" hide-details></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field v-model="detail.address" label="Dirección" hide-details></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field v-model="detail.email" label="Email" hide-details></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field v-model="detail.comuna" label="Comuna" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="detail.datos_bancarios"
                  rows="5"
                  label="Datos Bancarios"
                  placeholder="Banco: Estado
Tipo cuenta: Vista
Cta cte: xxxxxxxx
Rut: xxxxxxx-x
Email: email@email.com"
                  auto-grow
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="detail.datos_facturacion"
                  rows="5"
                  label="Datos Facturacion"
                  placeholder="Rut, Razon social, giro, direccion, comuna, etc"
                  auto-grow
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-spacer></v-spacer>
          <v-btn color="secondary" :loading="loading" :disabled="loading" flat @click="saveMyStore">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import appTitle from '../useful/title.vue'
import appTitleCard from '../useful/titleCard.vue'
import { MI_STORE } from '../../config'

export default {
  name: 'StoreGeneral',
  components: { appTitle, appTitleCard },
  props: ['store'],
  data: () => ({
    loading: false,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {},
    states: [
      { name: 'Florida', abbr: 'FL', id: 1 },
      { name: 'Georgia', abbr: 'GA', id: 2 },
      { name: 'Nebraska', abbr: 'NE', id: 3 },
      { name: 'California', abbr: 'CA', id: 4 },
      { name: 'New York', abbr: 'NY', id: 5 }
    ]
  }),
  computed: {
    schedules() {
      return this.$store.getters.getSchedules
    }
  },
  mounted() {
    this.detail = this.store
  },
  methods: {
    async saveMyStore() {
      try {
        await this.$http.post(`${MI_STORE}/home`, {
          store: this.detail
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    }
  }
}
</script>
